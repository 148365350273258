<template>
  <div class="home-wrapper">
    <div class="user-wrapper flex align-center">
      <van-image
        round
        width="1.5rem"
        height="1.5rem"
        :src="userInfo.avatar || defaultAvatar"
      />
      <div class="white f16 username">
        <span>{{ userInfo.nick_name }}</span>
      </div>
    </div>
    <div class="menu">
      <van-row type="flex">
        <van-col span="6" class="col" @click="skipSalaryList">
          <van-icon name="manager" class="menu-icon" />
          <span class="menu-text">查看工资</span>
        </van-col>
        <!-- <van-col span="6" class="col" @click="birthdayWishes">
          <van-icon name="manager" class="menu-icon" />
          <span class="menu-text">生日祝福</span>
        </van-col> -->
      </van-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "index",
  data() {
    return {};
  },
  created() {},
  methods: {
    /**
     * 跳转到工资列表
     */
    skipSalaryList() {
      this.$router.push({
        path: "/salary",
      });
    },
    // birthdayWishes() {
    //   this.$router.push({
    //     path: "/birthdayWishes",
    //   });
    // },
  },
  computed: {
    ...mapState(["userInfo", "defaultAvatar"]),
  },
};
</script>

<style scoped lang="scss">
.username {
  margin-left: 10px;
}
.home-wrapper {
  min-height: 100vh;
  background: linear-gradient(#487ee2, rgb(243, 243, 248));

  .user-wrapper {
    padding: 20px 20px 0 20px;
  }

  .menu {
    background: white;
    border-radius: 16px;
    min-height: 400px;
    padding-top: 20px;
    margin: 20px 30px 0 30px;
    .col {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .menu-text {
        margin-top: 6px;
        font-size: 12px;
      }
    }
  }
}
</style>
